import React from 'react';
import './Navbar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import {
    BrowserRouter as Router, Link, NavLink
  } from "react-router-dom";


const Navbar = props =>{
    return(
    <header className="navbar">
        <nav id="navbar">
            <div className="main-navbar">
            <div className="spacer"/>
            <div className="navbar-toggle-button">
                <DrawerToggleButton click = {props.drawerClickHandler} />
            </div>
            <div>
                <Link to = "/home" className="text-lynk"><div className="nav-title">WD&D</div></Link>
            </div>
        <div className="spacer3"/>
            <div className="navigation-items">
                <ul>
                <NavLink to = "/home" className="text-lynk"><li>Home</li></NavLink>
                <NavLink to = "/products" className="text-lynk"><li>Products</li></NavLink>
                <NavLink to = "/forums" className="text-lynk"><li>Forums</li></NavLink>
                <NavLink to = "/contact" className="text-lynk"><li>Contact</li></NavLink>
                </ul>
                
            </div>
        <div className="spacer3"/>
            <div className="navbar-user">
            <div className="navbar-user-profile name">          
            <Link to = "/log-in" className="text-lynk" title="User Profile"><svg className = "user-icon" version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 512 512"  xmlSpace="preserve">
                        <g>
                            <path className="st0" d="M256,0C114.801,0,0,114.883,0,256c0,67.282,25.918,130.883,73.039,179.039
                            C121.519,484.718,186.481,512,256,512c69.438,0,134.398-27.282,182.879-76.961C486,386.883,512,323.282,512,256
                            C512,114.883,397.121,0,256,0z M103.875,410.461c42.394-26.714,97.168-55.98,101.004-56.859h2.402v-46
                            c-5.524-2.883-9.282-10-11.844-17.039c-2.473-6.652-3.653-13.137-4.02-15.812c0-0.059-0.011-0.168-0.007-0.2
                            c-0.008,0.004-0.016,0-0.024,0.004c-0.007-0.038-0.019-0.117-0.027-0.156c-17.519,5.844-20.078-26.957-20.878-35.278
                            c-0.801-7.519,2.558-12.801,4.878-14.878c-0.558-1.203-1.281-2.961-1.922-5.043c0-0.078,0-0.078,0-0.078
                            c-6.719-30.402-2.797-39.281,10.641-63.519c13.602-24.562,47.602-48.242,67.441-47.922c1.52,0,3.039,0,4.481,0.16
                            c23.84,1.602,36.718,13.359,46.879,31.519c6.16,0,16.878,8.16,24.961,16.242c3.519,3.438,6.558,6.961,8.48,9.758
                            c5.598,8,6.719,20.883,5.84,32.723c-0.641,8.078-2.082,15.758-3.679,21.038v0.078c-0.641,2.082-1.282,3.84-1.922,5.043
                            c2.402,2.078,5.68,7.359,4.961,14.878c-0.878,8.321-3.441,41.122-20.878,35.278c0,0.164,0,0.32-0.082,0.485
                            c-0.801,5.515-3.679,25.835-15.84,32.718v45.699v0.141l0,0v0.16h2.321c3.574,0.816,51.351,26.238,92.07,51.297
                            c3.027,1.875,6.019,3.746,8.945,5.61C367.336,450.809,313.59,472.883,256,472.883c-52.379,0-101.672-18.367-140.883-52.09
                            C111.282,417.481,107.508,414.066,103.875,410.461z" ></path>
                        </g>
                </svg></Link>
                <div className="navbar-user ">
                    <Link to = "/log-in" className="text-lynk"><div>Log In</div></Link>
                </div>
                
            </div>
            
            </div>
            <div className="spacer"/>
        </div>     
        </nav>
    </header>
    )
}
export default Navbar;