import React from 'react';
import {Helmet} from "react-helmet";
import './PasswordRecovery.css'
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";

const PasswordRecovery = () =>{
    return(
        <div className="password-recovery">
            <Helmet>
            <title>Request Password - Web Development & Design</title>
            </Helmet>
            <div className="password-recovery-container">
                        <div className="password-recovery-box"> 
                        <p className="password-recovery-title">Reset Password</p>     
                        <form className="password-recovery-form">                           
                            <h6 className="password-recovery-subtitle" >Please enter the email address you registered with:</h6>
                            <label className="password-recovery-label" for="email">Email</label>
                            <input className="input" name="email" id="email" type="email" autoFocus />                           
                            <NavLink to = "/log-in" className="text-lynk register-small"><button id="password-recovery" className="password-recovery-button">Request Password</button></NavLink>
                        </form>
                        </div>
            </div>
        </div>
    )
}


export default PasswordRecovery;