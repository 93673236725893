import React from 'react';
import {Helmet} from "react-helmet";
import './Home.css';

const Home = () =>{
    
    return(
        <div className="home-container">
            <Helmet>
                <title>Web Development & Design</title>
            </Helmet>
        <h1>Home</h1>
       
        </div>
    )
}


export default Home;