import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import './Register.css';
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";
  import {auth} from '../../firebase'
//import {toast, ToastContainer} from 'react-toastify';
//import 'react-toastify';

const Register = () =>{
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
e.preventDefault()
const config = {
    url:'http://localhost:3000/register/complete',
    hadleCodeInApp: true
}
await auth.sendSignInLinkToEmail(email, config);
//toast.success(`Email is sent to ${email}, click the linck to complete your registration`);
// save user email in local store
Window.localStore.setItem('emailForRegistration', email);
//clear state
setEmail("");
    };

    const registerForm = () => 
    <form onSubmit ={handleSubmit} className="register-form">
    <h4 className="register-subtitle" >Create a New Account.</h4>                           
        <label className="register-label" for="firstName">First Name</label>
        <input className="input" name="firstName" id="firstName" autoFocus />

        <label className="register-label" for="lastName">Last Name</label>
        <input className="input" name="lastName" id="lastName" />

        <label className="register-label" for="email">Email</label>
        <input className="input" name="email" id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>

        <label className="register-label" for="email">Repeat Email</label>
        <input className="input" name="email" id="email" type="email" />

        <label className="register-label" for="password"> Password</label>
        <input className="input" name="password" id="password" type="password"/>

        <label className="register-label" for="password"> Repeat Password</label>
        <input className="input" name="password" id="password" type="password"/>
        <NavLink to = "/home"><button id="register" type="submit" className="register-button">Register</button></NavLink>
        <NavLink to = "/log-in" className="text-lynk register-small"><small className="register-small">Already Have an Account?</small></NavLink>
    </form>

    return(
        <div className="register">
            <Helmet>
            <title>Register - Web Development & Design</title>
            </Helmet>
            <div className="register-container">
                        <div className="register-box"> 
                        <p className="register-title">Register</p>     
                        {registerForm()}
                        
                        </div> 
            </div>
        </div>
    )
}


export default Register;