import React from 'react';
import {Helmet} from "react-helmet";
import './Login.css'
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";

const Login = () =>{
    return(
        <div className="login">
            <Helmet>
            <title>Log In - Web Development & Design</title>
            </Helmet>
            <div className="login-container">
                        <div className="login-box"> 
                            <p className="login-title">Log In</p>     
                            <form className="login-form">                           
                                <h4 className="login-subtitle" >Log in to your account</h4>
                                <label className="login-label" for="email">Email</label>
                                <input className="input" name="email" id="email" type="email" />

                                <label className="login-label" for="password"> Password</label>
                                <input className="input" name="password" id="password" type="password"/>
                                    <div className= "login-rememberme">
                                    <input name="remenberme" id="rememberme" type="checkbox"></input>
                                    <label>Remember me</label>
                                    </div>
                                <NavLink to = "/home"><button id="login" className="login-button">Log In</button></NavLink>
                                <NavLink to = "/register" className="text-lynk login-small"><small className="login-small">You Dont Have an Account?</small></NavLink>
                                <NavLink to = "/password-recovery" className="text-lynk login-small"><small className="login-small">Forgot your password?</small></NavLink>
                            </form>
                        </div> 
            </div>
        </div>
    )
}


export default Login;