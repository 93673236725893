import React from 'react';
import './TermsAndConditiions.css';
import {Helmet} from "react-helmet";

const TermsAndConditiions = () =>{
    return(
        <div className="terms-container">
            <Helmet>
                <title>Terms And Conditiions - Web Development & Design</title>
            </Helmet>
        <h1>TERMENI SI CONDITII SITE</h1>
    
            <div  className="terms-tab">
                <h2>Condiţii Generale pentru utilizarea site-ului www.webdevelopmentdesign.ro/</h2>
                <h4 className= "terms-h4">INTRODUCERE</h4>
                    <p>
                    Prezentele Condiţii Generale definesc condiţiile de utilizare a site-ului www.webdevelopmentdesign.ro de către potenţialii vizitatori sau clienţi. 
                    Accesând şi navigând pe acest site, acceptaţi termenii de utilizare descrişi în continuare.
                    </p>
                <h4 className= "terms-h4">DREPTURILE ASUPRA CONŢINUTULUI SITE-ULUI</h4>
                    <p>
                    Accesul si utilizarea paginii https://webdevelopmentdesign.ro  sunt gratuite si au scopul de a veni in ajutorul utilizatorilor pentru a găsi 
                    informațiile necesare în cel mai ușor si rapid mod posibil, conform cerințelor fiecăruia.

                    Informațiile prezente pe Web Development & Design sunt de interes general și sunt puse la dispoziția utilizatorilor în mod gratuit. 
                    Prin termenul „utilizator” al acestei pagini se înțelege orice persoana fizică sau juridică care va accesa pagina. 
                    Puteți copia și tipări conținutul paginii Web Development & Design pentru folosința dumneavoastră personală, fără intenții comerciale.
                    În orice alte situații, conținutul Web Development & Design nu poate fi reprodus, modificat sau exploatat.
                    </p>
                <h4 className= "terms-h4">NEANGAJAREA RĂSPUNDERII</h4>
                    <p>
                    Conţinutul informaţiilor se referă la descrierea într-un anumit grad de detaliere a activităţilor.
                    Nu se acordă nici o garanţie referitoare la:

                            – evitarea utilizării anevoioase sau întreruperii în utilizare a site-ului;
                            – neafectarea în sens negativ a altor sisteme prin utilizarea site-ului;
                            – inexistenţa pe site-ul pus la dispoziţie a viruşilor sau alte componente care ar putea dăuna utilizatorilor.
                    Web Development & Design nu poate fi responsabilă pentru nici un fel de daune directe sau indirecte produse prin utilizarea site-ului său.
                    Toate informațiile prezentate pe site cu privire la produsele Web Development & Design, sunt prezentate cu titlu informativ. 
                    Nimic din conținutul site-ului www.webdevelopmentdesign.ro nu poate constitui o ofertă fermă de a contracta și nu 
                    poate angaja răspunderea Web Development & Design în lipsa unor acorduri ulterioare.  
                    </p>
                <h4 className= "terms-h4">OBIECTIVUL CONŢINUTULUI SITE-ULUI</h4>
                    <p>
                    Obiectivul conţinutului site-ului este de a transmite informaţii actualizate şi exacte.

                    Web Development & Design nu poate garanta că prezentele pagini nu conţin erori, 
                    şi asigură că va depune toate diligenţele pentru realizarea unei informări corecte şi remedierea eventualelor erori.

                    Orice persoană care doreşte să-şi procure unul din serviciile sau produsele prezentate în site este rugată să contacteze 
                    Web Development & Design prin unul din mijloacele afişate în pagina de Contact a site-ului, pentru a se informa atât asupra disponibilităţii 
                    serviciului sau produsului în cauză cât şi asupra condiţiilor contractuale, tarifelor şi informaţiilor tehnice sau de altă natură.
                    </p>
                <h4 className= "terms-h4">INFORMAŢII PERSONALE</h4>
                    <p>
                    Când prin intermediul prezentului site vă sunt solicitate informaţii, aceasta are ca scop identificarea dumneavoastră sau posibilitatea de a vă contacta. 
                    Acest lucru este cu atât mai necesar în cazul folosirii aplicaţiilor disponibile pe site.

                    Natura informaţiilor solicitate se referă în special la date personale (nume, adresă, numere de telefon), 
                    adresă de e-mail, modul în care sunt utilizate sau intenţionează să fie utilizate produsele şi/sau serviciile Web Development & Design, 
                    dar poate include şi alte informaţii aflate în strânsă legătură cu utilizarea serviciilor şi/sau produselor solicitate.

                    Cu scopul de a răspunde mai bine nevoilor şi întrebărilor utilizatorilor site-ului, informaţiile solicitate prin intermediul acestui site vor face obiectul unei stocări şi prelucrări electronice.
                    </p>
                <h4 className= "terms-h4">COMUNICAREA CU VIZITATORII SITE-ULUI</h4>
                    <p>
                    Metodele prin care vizitatorii site-ului inițiază contactul cu noi sunt: chat online, adresă de e-mail, număr de telefon.
                    Această contactare are loc pentru a cere informații despre produsele noastre, pentru a beneficia de serviciile noastre.
                    </p>
                <h4 className= "terms-h4">LEGĂTURA CU ALTE SITE-URI</h4>
                    <p>
                    Prezentul site poate conţine legături sau trimiteri către alte site-uri considerate de Web Development & Design utile în legătură cu conţinutul site-ului său şi care nu se află sub controlul sau îndrumarea sa. 
                    În cazul utilizării acestor legături sau trimiteri se vor aplica condiţiile generale de utilizare corespunzătoare acelor site-uri.

                    Web Development & Design nu poate garanta/controla actualitatea/exactitatea informaţiilor prezente pe site-urile unor terţi, la care se face trimitere de pe site-ul său.
                    </p>
                <h4 className= "terms-h4">INFORMAŢIILE OFERITE PRIN INTERMEDIUL SITE-ULUI</h4>
                    <p>
                    Orice persoană care vizitează site-ul www.webdevelopmentdesign.ro şi care oferă date sau informaţii cu caracter personal prin intermediul acestui site 
                    îşi manifestă acordul în mod expres şi neechivoc pentru următoarele: prelucrarea acestor date şi informaţii personale de către Web Development & Design în vederea efectuării de studii de piaţă,  
                    soluţionarea de către Web Development & Design a cererilor, întrebărilor şi reclamaţiilor adresate (a se vedea pagina Contact din site); alte activităţi întreprinse de Web Development & Design şi permise de lege, 
                    ce nu fac obiectul unei aprobări din partea destinatarului.

                    Web Development & Design va păstra confidenţialitatea acestor informaţii.

                    Utilizarea în continuare a acestui site constituie acordul expres şi neechivoc al dumneavoastră în conformitate cu 
                    directivele GDPR pentru protecţia persoanelor cu privire la prelucrarea datelor cu caracter personal şi libera circulaţie a acestor date.
                    Pentru orice nelămurire în legătură cu exercitarea drepturilor dumneavoastră referitoare la utilizarea site-ului şi la protecţia utilizării vă rugăm să ne contactaţi prin intermediul secţiunii Contact din site.
                    </p>





            </div>
        
        </div>
    )
}


export default TermsAndConditiions;