import React, { Component } from 'react';
import './App.css';
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Toolbar/Navbar";
import Login from "./Components/Users/Login";
import Register from './Components/Users/Register';
import UserProfile from './Components/Users/UserProfile';
import PasswordRecovery from './Components/Users/PasswordRecovery';
import Home from "./Components/Home";
import Products from "./Components/Products";
import Forums from "./Components/Forums";
import Contact from "./Components/Contact";
import TermsAndConditiions from "./Components/Footer/TermsAndConditiions.js";
import FrequentQuestions from "./Components/Footer/FrequentQuestions.js";
import SideDrawer from "./Components/SideDrawer/SideDrawer"
import Backdrop from "./Components/Backdrop/Backdrop"
import ScrollToTop from "./ScrollToTop"
import ScrollButton from './Components/Tools/ScrollButton';
import {
  BrowserRouter as Router,
  NavLink,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";




class App extends Component { 
  state = {
    sideDrawerOpen: false
  }

  drawerToggleClickHandler = () =>{
    this.setState((prevState)=>{
      return{sideDrawerOpen: !prevState.sideDrawerOpen};
    })
  }

  backdropClickHandler = () =>{
    this.setState({sideDrawerOpen: false})
  }

  render() {  
    let backdrop;
    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop click ={this.drawerToggleClickHandler}/>
    }    
  return (
    <div className="App">
      <div className ="page-container">
      <HashRouter>
      
      <Navbar drawerClickHandler = {this.drawerToggleClickHandler} />
      <SideDrawer show = {this.state.sideDrawerOpen} />
      {backdrop}
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL.   */}
            <Switch>
            <Route path="/" exact component = {Home}>
                <Home />
              </Route>   
            <Route path="/home" component = {Home}>
                <Home />
              </Route>             
              <Route path="/products" component = {Products}>
                <Products />
              </Route>
              <Route path="/Forums" component = {Forums}>
                <Forums />
              </Route>
              <Route path="/contact" component = {Contact}>
                <Contact />
              </Route>
              <Route path="/user-profile" component = {UserProfile}>
                <UserProfile />
              </Route>
              <Route path="/log-in" component = {Login}>
                <Login />
                </Route>
                <Route path="/register" component = {Register}>
                <Register />
                </Route>
                <Route path="/password-recovery" component = {PasswordRecovery}>
                <PasswordRecovery />
                </Route>               
            </Switch>
              
            <Switch>
            <Route path="/frequent-questions" exact component = {FrequentQuestions}>
                <FrequentQuestions />
                <ScrollToTop />
                <ScrollButton />
              </Route>
      <Route path="/terms-and-conditiions" exact component = {TermsAndConditiions}>
                <TermsAndConditiions />
                <ScrollToTop />
                <ScrollButton />
              </Route>
      </Switch>
      
      <Footer/>
        </HashRouter>
                
    </div>    
    </div>
  );
}
}
export default (App);
