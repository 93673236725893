import firebase from 'firebase';
import 'firebase/auth'
// The core Firebase JS SDK is always required and must be listed first
//src="https://www.gstatic.com/firebasejs/8.1.1/firebase-app.js"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//src="https://www.gstatic.com/firebasejs/8.1.1/firebase-analytics.js"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAKXaJZbNx2qW2VWsCTiUS3tB8Ek31lgJM",
    authDomain: "web-development-design.firebaseapp.com",
    databaseURL: "https://web-development-design.firebaseio.com",
    projectId: "web-development-design",
    storageBucket: "web-development-design.appspot.com",
    messagingSenderId: "398148248424",
    appId: "1:398148248424:web:b8965a091eb3037d33ecdd",
    measurementId: "G-LJ7VW4G27K"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  //export
  export const auth = firebase.auth()
  export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();