import React from 'react';
import './FrequentQuestions.css';
import {Helmet} from "react-helmet";
import { genericHashLink } from 'react-router-hash-link';
import GatsbyLink from 'gatsby-link';
import { HashLink } from 'react-router-hash-link';

const MyHashLink = genericHashLink(GatsbyLink);

const FrequentQuestions = () =>{
    return(
        <div className="frequent-questions-container">
            <Helmet>
                <title>Frequent Questions - Web Development & Design</title>
            </Helmet>
        <h1>INTREBARI FRECVENTE</h1>    
            <div className = "frequent-questions-body">
                <div className= "questions-left-tab">
                    <hr className = "hr-intrebare"></hr>
                    <div className = "questions">
                        <HashLink smooth to="#questions-1">1 Intrebare</HashLink>
                        <HashLink smooth to="#questions-2">2 Intrebare</HashLink>
                        <HashLink smooth to="#questions-3">3 Intrebare</HashLink>
                        <HashLink smooth to="#questions-4">4 Intrebare</HashLink>
                        <HashLink smooth to="#questions-5">5 Intrebare</HashLink>    
                    </div>
                    <hr className = "hr-intrebare"></hr>
                </div>

                <div className="answer-tab">
                <div>
                        <h4 id = "questions-1" className ="answer-h4">1 Raspuns</h4>
                            <p>Lorem ipsum dolor sit amet, ex fabulas deterruisset sea. Eum delenit temporibus ea, mei ex quod impetus mentitum, ad sale dicta per. 
                                Id fugit velit principes quo, ex sea clita democritum, pro id tamquam consequat. Homero electram interpretaris has ei. Sed congue alterum ne. 
                                Reque movet quaestio pro et. Usu civibus corpora ea, omnium bonorum ne vel.  Te idque quidam nam, oratio possim malorum vel in, ea diceret prodesset maiestatis nec. 
                                Ea ius impetus consequat. His id zril munere abhorreant, mea ridens omittantur ut. Affert accusata ea sed. Ne mei eius velit tractatos, ius id reque facilisi.

                                Eos sumo tota impedit ne, no eum dicam quaeque quaerendum. Id equidem aliquando scripserit vim, oratio verear epicurei in pri, ad tale antiopam accusamus sit. 
                                Ea viderer lucilius has, et eam saepe labitur. In iriure fuisset usu. Sed cu justo graece, probo fuisset mea ne.

                                In mel dictas feugait, nec ea explicari constituto. Sea ad prima nonumy voluptatum. Eam an labitur pertinacia, mel sumo dicam possit te. At mei forensibus moderatius. 
                                Ea sanctus albucius eos, eros intellegat adipiscing no nec. Cu nec veritus legendos.

                                Habeo quodsi eu mea, decore prompta eruditi et est. Eius deleniti id qui. In tamquam equidem qui. Est te sale deleniti, duo unum solum voluptua ea. Eu sumo minimum vis, 
                                mei ei ornatus probatus scripserit.

                                Vim convenire vituperata consequuntur at, aeque aeterno vulputate eum ne, qui legere scripserit concludaturque an. Nisl consequuntur at usu. 
                                Omnis corrumpit vel ea, cu modus senserit sententiae pro, vis ea alienum consulatu voluptaria. No impetus imperdiet duo. Vis deleniti placerat consulatu ea, duo in alii sale, 
                                has semper intellegat ut. His ne graeco luptatum, quaeque fabellas forensibus vis at.

                                Hinc persius id sit, eu vel adhuc tritani recusabo. Populo equidem vel ut, at regione numquam interpretaris duo, id meis fuisset appellantur vel. 
                                Pro ea nulla harum molestiae, quo cu eirmod aperiri, sale civibus evertitur at vix. Quo amet tota libris cu, et qui audiam perfecto.

                                Deserunt intellegebat in mei, eum in quas suscipiantur. In pri suscipit necessitatibus, sea eu legere possit. Probo illud quidam eu eos, illum iusto splendide no mel. E
                                um et harum menandri vulputate, cu mei ponderum senserit, sumo.
                            </p>
                        <h4 id = "questions-2" className ="answer-h4">2 Raspuns</h4>
                            <p>Ea debet deleniti mel, at eos esse ridens moderatius, graeci repudiare eos ex. Mel justo mundi postea cu, nam aliquid debitis definitionem no. In epicurei elaboraret percipitur vel, 
                                autem accommodare per ut. Sit quod porro eloquentiam ex, eruditi mediocritatem eu mea. Cu vivendum maiestatis usu.
                                Eos sumo tota impedit ne, no eum dicam quaeque quaerendum. Id equidem aliquando scripserit vim, oratio verear epicurei in pri, ad tale antiopam accusamus sit. 
                                Ea viderer lucilius has, et eam saepe labitur. In iriure fuisset usu. Sed cu justo graece, probo fuisset mea ne.

                                In mel dictas feugait, nec ea explicari constituto. Sea ad prima nonumy voluptatum. Eam an labitur pertinacia, mel sumo dicam possit te. At mei forensibus moderatius. 
                                Ea sanctus albucius eos, eros intellegat adipiscing no nec. Cu nec veritus legendos.

                                Habeo quodsi eu mea, decore prompta eruditi et est. Eius deleniti id qui. In tamquam equidem qui. Est te sale deleniti, duo unum solum voluptua ea. Eu sumo minimum vis, 
                                mei ei ornatus probatus scripserit.

                                Vim convenire vituperata consequuntur at, aeque aeterno vulputate eum ne, qui legere scripserit concludaturque an. Nisl consequuntur at usu. 
                                Omnis corrumpit vel ea, cu modus senserit sententiae pro, vis ea alienum consulatu voluptaria. No impetus imperdiet duo. Vis deleniti placerat consulatu ea, duo in alii sale, 
                                has semper intellegat ut. His ne graeco luptatum, quaeque fabellas forensibus vis at.
                                </p>
                        <h4 id = "questions-3" className ="answer-h4">3 Raspuns</h4>
                            <p>No eum nominavi nominati ocurreret, pro at etiam congue. Periculis consequuntur eu usu. Modus urbanitas cum an, nobis blandit atomorum et vis. Quo te movet expetendis. 
                                Qui id labitur utroque.
                                Eos sumo tota impedit ne, no eum dicam quaeque quaerendum. Id equidem aliquando scripserit vim, oratio verear epicurei in pri, ad tale antiopam accusamus sit. 
                                Ea viderer lucilius has, et eam saepe labitur. In iriure fuisset usu. Sed cu justo graece, probo fuisset mea ne.

                                In mel dictas feugait, nec ea explicari constituto. Sea ad prima nonumy voluptatum. Eam an labitur pertinacia, mel sumo dicam possit te. At mei forensibus moderatius. 
                                Ea sanctus albucius eos, eros intellegat adipiscing no nec. Cu nec veritus legendos.

                                Habeo quodsi eu mea, decore prompta eruditi et est. Eius deleniti id qui. In tamquam equidem qui. Est te sale deleniti, duo unum solum voluptua ea. Eu sumo minimum vis, 
                                mei ei ornatus probatus scripserit.

                                Vim convenire vituperata consequuntur at, aeque aeterno vulputate eum ne, qui legere scripserit concludaturque an. Nisl consequuntur at usu. 
                                Omnis corrumpit vel ea, cu modus senserit sententiae pro, vis ea alienum consulatu voluptaria. No impetus imperdiet duo. Vis deleniti placerat consulatu ea, duo in alii sale, 
                                has semper intellegat ut. His ne graeco luptatum, quaeque fabellas forensibus vis at.
                            </p>
                        <h4 id = "questions-4" className ="answer-h4">4 Raspuns</h4>
                        <p>No laudem impedit dissentias vix, ludus suscipit ius in, vide dolore aperiri ea sit. No nec purto mutat nemore, debet reprimique reprehendunt pro eu. Eu dicta dolore eos. 
                            Ne agam perfecto usu. Iriure accumsan usu ea.
                            Eos sumo tota impedit ne, no eum dicam quaeque quaerendum. Id equidem aliquando scripserit vim, oratio verear epicurei in pri, ad tale antiopam accusamus sit. 
                                Ea viderer lucilius has, et eam saepe labitur. In iriure fuisset usu. Sed cu justo graece, probo fuisset mea ne.

                                In mel dictas feugait, nec ea explicari constituto. Sea ad prima nonumy voluptatum. Eam an labitur pertinacia, mel sumo dicam possit te. At mei forensibus moderatius. 
                                Ea sanctus albucius eos, eros intellegat adipiscing no nec. Cu nec veritus legendos.

                                Habeo quodsi eu mea, decore prompta eruditi et est. Eius deleniti id qui. In tamquam equidem qui. Est te sale deleniti, duo unum solum voluptua ea. Eu sumo minimum vis, 
                                mei ei ornatus probatus scripserit.

                                Vim convenire vituperata consequuntur at, aeque aeterno vulputate eum ne, qui legere scripserit concludaturque an. Nisl consequuntur at usu. 
                                Omnis corrumpit vel ea, cu modus senserit sententiae pro, vis ea alienum consulatu voluptaria. No impetus imperdiet duo. Vis deleniti placerat consulatu ea, duo in alii sale, 
                                has semper intellegat ut. His ne graeco luptatum, quaeque fabellas forensibus vis at.
                                </p>
                        <h4 id = "questions-5" className ="answer-h4">5 Raspuns</h4>
                            <p>Ea meis ipsum utamur eam, erroribus repudiare et sit. Mei nonumy integre hendrerit id, eos decore convenire an. Ne timeam adipiscing quo, mutat efficiantur pro te, 
                                at iudico ocurreret evertitur pro. In sea harum commodo. Sed summo euismod denique in. Hinc deterruisset eam cu, per iisque principes eu, ne dicit volumus quo.
                                Eos sumo tota impedit ne, no eum dicam quaeque quaerendum. Id equidem aliquando scripserit vim, oratio verear epicurei in pri, ad tale antiopam accusamus sit. 
                                Ea viderer lucilius has, et eam saepe labitur. In iriure fuisset usu. Sed cu justo graece, probo fuisset mea ne.

                                In mel dictas feugait, nec ea explicari constituto. Sea ad prima nonumy voluptatum. Eam an labitur pertinacia, mel sumo dicam possit te. At mei forensibus moderatius. 
                                Ea sanctus albucius eos, eros intellegat adipiscing no nec. Cu nec veritus legendos.

                                Habeo quodsi eu mea, decore prompta eruditi et est. Eius deleniti id qui. In tamquam equidem qui. Est te sale deleniti, duo unum solum voluptua ea. Eu sumo minimum vis, 
                                mei ei ornatus probatus scripserit.

                                Vim convenire vituperata consequuntur at, aeque aeterno vulputate eum ne, qui legere scripserit concludaturque an. Nisl consequuntur at usu. 
                                Omnis corrumpit vel ea, cu modus senserit sententiae pro, vis ea alienum consulatu voluptaria. No impetus imperdiet duo. Vis deleniti placerat consulatu ea, duo in alii sale, 
                                has semper intellegat ut. His ne graeco luptatum, quaeque fabellas forensibus vis at.
                                </p>
                    </div>
                </div>  
            </div>       
        </div>
    )
}


export default FrequentQuestions;