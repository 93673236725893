import React from 'react';
import {Helmet} from "react-helmet";
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";
  

const Products = () =>{
    return(
        <div className="products-container">
         <Helmet>
                <title>Products - Web Development & Design</title>
        </Helmet>  
        <h1 className="h1">Products</h1> 
        
        </div>
    )
}


export default Products;