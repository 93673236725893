import React from 'react';
import {Helmet} from "react-helmet";
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";
  
const Contact = () =>{
    return(
        <div className="contact-container">
            <Helmet>
                <title>Contact - Web Development & Design</title>
            </Helmet>
        <h1>Contact</h1>
        
        </div>
    )
}


export default Contact