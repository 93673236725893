import React from 'react';
import {Helmet} from "react-helmet";
import './Register.css';
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";

const UserProfile = () =>{
    return(
        <div className="user-profile">
            <Helmet>
            <title>User Profile | Web Development & Design</title>
            </Helmet>
            <div className="user-profile-container">
                        <div className="user-profile-box"> 
                        <p className="user-profile-title">Register</p>     
                        <form className="user-profile-form">
                        <h4 className="user-profile-subtitle" >Create a New Account.</h4>                           
                            <label className="user-profile-label" for="firstName">First Name</label>
                            <input className="input" name="firstName" id="firstName" />

                            <label className="user-profile-label" for="lastName">Last Name</label>
                            <input className="input" name="lastName" id="lastName" />

                            <label className="user-profile-label" for="email">Email</label>
                            <input className="input" name="email" id="email" type="email" />

                            <label className="user-profile-label" for="email">Repeat Email</label>
                            <input className="input" name="email" id="email" type="email" />

                            <label className="user-profile-label" for="password"> Password</label>
                            <input className="input" name="password" id="password" type="password"/>

                            <label className="user-profile-label" for="password"> Repeat Password</label>
                            <input className="input" name="password" id="password" type="password"/>
                            <NavLink to = "/home"><button id="user-profile" className="user-profile-button">Create Account</button></NavLink>
                            <NavLink to = "/log-in" className="text-lynk user-profile-small">
                            <small className="user-profile-small">Already Have an Account?</small></NavLink>
                        </form>
                        
                        </div> 
            </div>
        </div>
    )
}


export default UserProfile;