import React from 'react';
import './Footer.css';
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";

  
const Footer = () =>{
    return(
    <nav className = "nav-footer">
        <div className="footer">
        <div className="footer-container ">
            
                <div className = "row">
                    {/*Column1*/}
                    <div className = "col">                       
                    <Link to = "/home" className="text-lynk"><h3 className = "footer-title" title="Home">Web Development & Design</h3></Link>
                        <div className = "icon-footer-container">
                            <div  title="Facebook">
                            <svg className = "footer-icon facebook" version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox={"0 0 512 512"}  xmlSpace="preserve">
                                    <g>
                                        <path className="st0" d="M483.758,0H28.242C12.641,0,0,12.637,0,28.238V483.68C0,499.359,12.641,512,28.242,512h219.679V340.957
                                            h-74.336h-0.066v-86.16h74.402v-63.598c0-13.801,1.578-26.422,4.57-37.824c0.094-0.344,0.215-0.66,0.309-1
                                            c0.875-3.215,1.855-6.34,2.953-9.356c0.871-2.367,1.871-4.633,2.879-6.886c0.418-0.942,0.785-1.93,1.226-2.851
                                            c1.461-3.024,3.051-5.934,4.762-8.734c0.082-0.137,0.153-0.282,0.238-0.418c3.614-5.863,7.782-11.214,12.454-16.058
                                            c0.109-0.114,0.21-0.242,0.32-0.355c2.012-2.063,4.164-3.981,6.363-5.848c0.492-0.418,0.95-0.875,1.45-1.282
                                            c1.734-1.414,3.594-2.691,5.438-3.984c1.098-0.766,2.148-1.586,3.281-2.305c1.195-0.766,2.477-1.422,3.715-2.141
                                            c1.926-1.106,3.824-2.242,5.843-3.222c0.348-0.176,0.727-0.309,1.078-0.481c15.566-7.359,33.754-11.175,53.918-11.175
                                            c31.524,0,58.641,2.402,66.481,3.441v77.039h-45.598c-35.762,0-42.722,17.039-42.722,42v55.039h76.457l8.902,0.02l-11.118,86.14
                                            h-73.855h-0.226l0,0h-0.16V512h146.878C499.359,512,512,499.359,512,483.68V28.238C512,12.637,499.359,0,483.758,0z"></path>
                                    </g>
                                </svg>
                            </div>
                            <div title="Instagram">
                                <svg className = "footer-icon instagram" version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={"0 0 512 512"} xmlSpace="preserve">

                                    <g>
                                        <path className="st0" d="M405.43,0H106.57C47.774,0,0,47.817,0,106.57V405.43C0,464.183,47.774,512,106.57,512H405.43
                                            C464.183,512,512,464.183,512,405.43V106.57C512,47.817,464.183,0,405.43,0z M255.676,119.45h-23.168V50.969h23.168v68.222V119.45z
                                            M304.898,178.543c1.43,0.808,2.863,1.602,4.246,2.473c0.922,0.586,1.801,1.234,2.703,1.847c1.281,0.867,2.574,1.715,3.808,2.641
                                            c0.57,0.426,1.106,0.898,1.664,1.336c15.91,12.461,27.973,29.613,33.993,49.422c2.824,9.172,4.308,18.902,4.308,29.004
                                            c0,55.047-44.621,99.621-99.621,99.621c-55.043,0-99.617-44.574-99.617-99.621c0-10.102,1.48-19.832,4.308-29.004
                                            c12.418-40.867,50.367-70.617,95.309-70.617c16.762,0,32.554,4.156,46.422,11.476C303.266,177.566,304.07,178.074,304.898,178.543z
                                            M426.652,145.91c0,6.301-5.098,11.394-11.398,11.394h-48.281c-6.254,0-11.352-5.094-11.352-11.394V97.629
                                            c0-6.305,5.098-11.398,11.352-11.398h48.281c6.301,0,11.398,5.094,11.398,11.398V145.91z M183.442,119.45h-23.168V50.969h23.168
                                            V119.45z M111.203,119.45H88.039V54.164c5.789-2.082,12.046-3.195,18.531-3.195h4.633V119.45z M50.969,236.262h58.984l12.462,0.031
                                            c-2.035,9.352-3.102,19.024-3.102,28.973c0,75.386,61.301,136.687,136.687,136.687c75.344,0,136.687-61.301,136.687-136.687
                                            c0-9.242-1.007-18.218-2.77-26.949c-0.125-0.684-0.175-1.375-0.308-2.055h71.422V405.43c0,30.582-25.019,55.602-55.602,55.602
                                            H106.57c-30.578,0-55.602-25.019-55.602-55.602V236.262z"></path>
                                        <path className="st0" d="M194.098,236.262c-4.121,8.805-6.442,18.625-6.442,29.004c0,37.718,30.582,68.301,68.344,68.301
                                            c37.718,0,68.297-30.582,68.297-68.301c0-10.379-2.313-20.199-6.438-29.004c-10.938-23.214-34.519-39.293-61.859-39.293
                                            C228.617,196.969,205.031,213.047,194.098,236.262z M291.078,227.457c2.5,2.688,4.355,5.699,5.652,8.805
                                            c3.66,9.082,2.176,19.137-4.586,25.39c-9.082,8.387-24.328,6.672-34.012-3.843c-5.789-6.305-8.293-14.321-7.414-21.547
                                            c0.602-4.82,2.688-9.266,6.348-12.648C266.149,215.226,281.348,216.942,291.078,227.457z"></path>
                                    </g>
                                </svg>
                            </div>
                            <div title="Twitter">
                            <svg className = "footer-icon twitter" version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={"0 0 512 512"} xmlSpace="preserve">

                                <g>
                                    <path className="st0" d="M465.598,24H46.398C20.797,24,0,44.801,0,70.481v371.117C0,467.278,20.797,488,46.398,488h419.199
                                        C491.199,488,512,467.278,512,441.598V70.481C512,44.801,491.199,24,465.598,24z M327.519,74.402
                                        c0-11.602,9.438-21.043,21.039-21.043c11.52,0,20.961,9.442,20.961,21.043c0,11.519-9.441,20.957-20.961,20.957
                                        C336.957,95.359,327.519,85.922,327.519,74.402z M397.519,74.402c0-11.602,9.438-21.043,21.039-21.043
                                        c11.52,0,20.961,9.442,20.961,21.043c0,11.519-9.441,20.957-20.961,20.957C406.957,95.359,397.519,85.922,397.519,74.402z M48,440
                                        V120h416v320H48z"></path>
                                    <path className="st0" d="M381.59,202.262c0,0,0.004,0,0.004-0.004c-0.043,0.02-0.09,0.039-0.137,0.055
                                        c-0.031,0.015-0.066,0.023-0.102,0.038c0.062-0.086,0.129-0.156,0.18-0.238c-7.367,3.266-15.191,5.644-23.336,7.125
                                        c-1.691,0.278-3.394,0.5-5.114,0.683c-0.023,0-0.042,0.008-0.066,0.008c-0.214,0-0.43-0.008-0.648-0.015
                                        c8.246-5.059,14.882-12.387,19.207-21.094c1.164-2.258,2.27-4.55,3.102-6.961c0,0,0,0-0.004,0.004c0-0.004,0-0.004,0-0.004
                                        c-0.035,0.024-0.078,0.039-0.114,0.062c-0.019,0.012-0.043,0.02-0.062,0.031c0.023-0.078,0.07-0.148,0.094-0.23
                                        c-9.949,5.902-20.98,10.192-32.714,12.5c-9.398-10.011-22.794-16.27-37.61-16.27c-28.457,0-51.523,23.07-51.523,51.524
                                        c0,3.672,0.426,7.242,1.156,10.699c0.05,0.343,0.082,0.687,0.145,1.035c-37.43-1.887-71.141-17.805-96.004-42.602
                                        c-3.508-3.531-6.867-7.238-10.02-11.149v0.004l0,0c-0.007,0.012-0.015,0.027-0.023,0.043c-0.035-0.043-0.078-0.082-0.117-0.125
                                        c-4.438,7.609-6.973,16.461-6.973,25.902c0,14.657,6.175,27.836,16.004,37.223c0.816,0.797,1.769,1.461,2.625,2.218
                                        c1.32,1.191,2.606,2.321,3.844,3.402c-8.278-0.332-16.066-2.613-22.891-6.402c-0.008,0.215-0.008,0.434-0.008,0.649
                                        c0,20.758,12.367,38.508,30.063,46.668c2.554,1.21,5.222,2.195,7.972,3.019c0.922,0.317,1.852,0.656,2.766,0.938
                                        c-4.168,1.09-8.527,1.703-13.038,1.703c-2.684,0-5.294-0.266-7.863-0.664c-0.555-0.114-1.137-0.164-1.684-0.313l0.004,0.004l0,0
                                        v0.008c0.012,0.019,0.019,0.043,0.023,0.062c-0.054-0.012-0.117-0.012-0.179-0.023c5.742,17.926,21.046,31.574,39.906,34.969
                                        c1.555,0.297,3.168,0.378,4.758,0.538c1.074,0.145,2.106,0.321,3.203,0.469c-17.59,13.696-39.699,21.863-63.727,21.863
                                        c-3.472,0-6.886-0.269-10.277-0.601c-0.641-0.098-1.294-0.133-1.926-0.254c0.024,0.012,0.043,0.023,0.066,0.039
                                        c-0.003-0.004-0.007-0.004-0.007-0.004c0.066,0.043,0.137,0.078,0.203,0.121c-0.114-0.011-0.23-0.007-0.344-0.019
                                        c22.797,14.618,49.879,23.149,78.977,23.149c2.492,0,4.832-0.262,7.262-0.371c1.398-0.059,2.797-0.118,4.172-0.211
                                        c4.105-0.285,8.113-0.73,12.042-1.313c1.887-0.277,3.778-0.542,5.602-0.882c2.387-0.446,4.742-0.926,7.059-1.477
                                        c3.062-0.726,6.05-1.523,8.937-2.41c2.078-0.637,4.11-1.336,6.126-2.059c2.343-0.84,4.613-1.726,6.836-2.66
                                        c2.453-1.027,4.855-2.11,7.21-3.262c1.699-0.832,3.344-1.695,4.965-2.57c2.75-1.488,5.438-3.035,8.043-4.68
                                        c1.05-0.664,2.058-1.343,3.07-2.023c8.05-5.39,15.39-11.496,21.992-18.214c0,0,0.008-0.004,0.008-0.008
                                        c1.278-1.297,2.656-2.52,3.875-3.859c0.21-0.234,0.434-0.462,0.648-0.699c2.664-2.965,5.188-6.031,7.586-9.176
                                        c0.57-0.75,1.156-1.492,1.71-2.254c2.031-2.769,3.938-5.605,5.758-8.488c0.734-1.156,1.469-2.297,2.164-3.469
                                        c1.629-2.722,3.133-5.5,4.57-8.301c0.664-1.289,1.335-2.566,1.961-3.875c1.438-3.003,2.75-6.05,3.984-9.117
                                        c0.414-1.031,0.844-2.047,1.234-3.086c1.41-3.727,2.649-7.497,3.758-11.282c0.086-0.301,0.188-0.602,0.266-0.902
                                        c3.805-13.282,5.754-26.809,5.754-39.949c0-2.234-0.051-4.454-0.153-6.665c1.172-0.843,2.184-1.886,3.317-2.781
                                        c1.582-1.25,3.129-2.547,4.641-3.894c2.23-1.989,4.355-4.051,6.406-6.223c1.254-1.324,2.477-2.687,3.672-4.09
                                        c1.012-1.187,2.161-2.25,3.122-3.48c1.531-1.906,3.035-3.836,4.445-5.833c0.062-0.074,0.122-0.148,0.18-0.222H381.59z"></path>
                                </g>
                                </svg>
                            </div> 
                        </div>                                                                                    
                    </div>


                    {/*Column2*/}
                    <div className = "col">
                        <h4>Informații Utile</h4>
                        <ul className ="list-unstiled">                        
                        <NavLink to = "/frequent-questions" className="text-lynk"><li>Intrebari frecvente</li></NavLink>                        
                        <li></li>
                        <NavLink to = "/terms-and-conditiions" className="text-lynk"><li>Termeni și condiții</li></NavLink>
                        </ul>
                    </div>
                    {/*Column3*/}
                    <div className = "col">
                        <h4>Contact</h4>
                        <ul className ="list-unstiled">
                        <li></li>
                        <li>Email: contact@xyz.ro</li>
                        <li>Telefon: 074321xxxx</li>
                        </ul>
                    </div>
                </div>
                
            
        </div>


        <div className="footer-bar">
            <p> &copy;{new Date().getFullYear()}  Web Development & Design</p>
            <p className="footer-pipe">|</p>
            <p>Realizat de Lucian Brad.</p>
        </div>
        </div>
    </nav>
    )
}

export default Footer;