import React from 'react';
import './SideDrawer.css';
import {
    BrowserRouter as Router, Link
  } from "react-router-dom";

const sideDrawer = props => {
    let drawerClasses = 'side-drawer'
    if(props.show){
    drawerClasses = 'side-drawer open'
    }
        return (
        <nav className={drawerClasses}>
            <ul>
                <Link to = "/home" className="side-drawer-text-lynk"><li>Home</li></Link>
                <Link to = "/products" className="side-drawer-text-lynk"><li>Products</li></Link>
                <Link to = "/forums" className="side-drawer-text-lynk"><li>Forums</li></Link>
                <Link to = "/contact" className="side-drawer-text-lynk"><li>Contact</li></Link>
                </ul>
        </nav>)
};

export default sideDrawer;