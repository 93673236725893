import React from 'react';
import './Forums.css'
import {Helmet} from "react-helmet";
import {
    BrowserRouter as Router,
    NavLink,
    Link
  } from "react-router-dom";
  
const Forums = () =>{
    return(
        <div className="forums-container">
            <Helmet>
                <title>Forums - Web Development & Design</title>
            </Helmet>
        <h1>Forums</h1>
          {/*   <div className = "under-construction">
          <img src={ScreenPics} alt="Under Construction" />            
          </div>*/}
        </div>
    )
}


export default Forums;