import React, {useState} from 'react';
import './ScrollButton.css';
const ScrollButton = () =>{
  
    const [visible, setVisible] = useState(false)
    
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 350){
        setVisible(true)
      } 
      else if (scrolled <= 350){
        setVisible(false)
      }
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    };
    
    window.addEventListener('scroll', toggleVisible);
    
    return (
        <>
        
        <button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} className="button-back-to-top">
        <svg className = "scroll-to-top-icon" version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 1024 768"  xmlSpace="preserve">
                    <g>
                        <path className="st0" d="M99.84 678.4c0-17.92 7.68-35.84 20.48-48.64L460.8 299.52c28.16-25.6 71.68-25.6 99.84 0l337.92 327.68c28.16 25.6 28.16 69.12 0 97.28s-71.68 28.16-99.84 0l-289.28-281.6-289.28 281.6c-28.16 28.16-71.68 28.16-99.84 0-10.24-12.8-20.48-30.72-20.48-46.08z" fill="#d81e06" ></path>
                    </g>
            
            </svg>Back To Top</button>
                  
        </>
    );
  }
    
  export default ScrollButton;